* {
    box-sizing: border-box;
};

body {
    background: var(--background);
    color: var(--positive);
    font-family: 'JetBrains Mono', monospace;

    margin: 0;
    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
}

.material-symbols-outlined {
    font-size: 32px;
}

#icons {
    position: absolute;
    top: 32px;
    right: 32px;

    cursor: pointer;

    a {
        color: var(--positive);
    }
}

#circles {
    width: calc(100% - 100px);
    max-width: 400px;
    aspect-ratio: 1;
    height: auto;
}

#comments {
    font-weight: bold;
    color: var(--negative);

    white-space: pre-line;
}

#code {
    background: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;

    border: none;
    outline: none;
    padding: 0;

    width: 32ch;
}

#settings {
    position: absolute;
    bottom: -300px;
    left: 0;
    right: 0;
    height: 300px;

    background: var(--background);
    border-top: 1px solid white;

    padding: 1em;

    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-auto-rows: min-content;
    gap: 6px;

    transition: bottom 0.5s;
    &.open {
        bottom: 0;
    }

    &.disabled {
        display: none;
    }
}

.color-wrap {
    height: 1.5em;
    border: 1px solid white;
    border-radius: 4px;

    input {
        opacity: 0;
        position: relative;
        top: -5px;
    }

    $colors: background, positive, negative;
    @each $color in $colors {
        &.#{$color} {
            background: var(--#{$color});
        }
    }
}
