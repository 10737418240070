* {
  box-sizing: border-box;
}

body {
  background: var(--background);
  color: var(--positive);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  height: 100vh;
  margin: 0;
  font-family: JetBrains Mono, monospace;
  display: flex;
  overflow: hidden;
}

.material-symbols-outlined {
  font-size: 32px;
}

#icons {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
}

#icons a {
  color: var(--positive);
}

#circles {
  aspect-ratio: 1;
  width: calc(100% - 100px);
  max-width: 400px;
  height: auto;
}

#comments {
  color: var(--negative);
  white-space: pre-line;
  font-weight: bold;
}

#code {
  background: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  width: 32ch;
  padding: 0;
}

#settings {
  background: var(--background);
  border-top: 1px solid #fff;
  grid-template-columns: repeat(2, min-content);
  grid-auto-rows: min-content;
  gap: 6px;
  height: 300px;
  padding: 1em;
  transition: bottom .5s;
  display: grid;
  position: absolute;
  bottom: -300px;
  left: 0;
  right: 0;
}

#settings.open {
  bottom: 0;
}

#settings.disabled {
  display: none;
}

.color-wrap {
  border: 1px solid #fff;
  border-radius: 4px;
  height: 1.5em;
}

.color-wrap input {
  opacity: 0;
  position: relative;
  top: -5px;
}

.color-wrap.background {
  background: var(--background);
}

.color-wrap.positive {
  background: var(--positive);
}

.color-wrap.negative {
  background: var(--negative);
}
/*# sourceMappingURL=index.249c1244.css.map */
